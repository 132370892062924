.swole-root {
  display: flex;
  height: 100vh;
  overflow: hidden;

  .responsive-msg {
    padding: 30px;
    margin: auto;
    text-align: center;

    .logo-wrapper {
      width: 50%;
      min-width: 200px;
      max-height: 100px;
      margin: 20px auto;

      .logo {
        height: 100%;
        width: 100%;
      }

    }
  }
}

.page-content {
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  background-color: $grey-200;
  position: relative;

  .page-body {
    flex: 1;
    padding: 0 10px 10px;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;

  }
}


.MuiPopover-paper {
  border-radius: 18px !important;

  .material-select-wrapper {
    padding: 10px;
    margin-bottom: 0;
  }
}


@media (max-width: $client-mobile-width) {
  .swole-root {
    flex-direction: column-reverse;
  }
  .MuiPaper-root {
    margin: 10px !important;
  }
  .MuiDrawer-paper {
    margin: 0 !important;
  }
}