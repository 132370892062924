.food-list {
  flex: 1;
  padding: 5px 10px 10px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;

  .food-list-container {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);
  }
}

.food-card-container {
  display: flex;
  flex-direction: column;
}

.food-card {
  flex: 1;
  border-radius: 10px;
  box-shadow: $surround-shadow-light;
  padding: 10px;

  .food-name {
    font-weight: 500;
    margin-bottom: 5px;
    font-size: 16px;
  }

  .food-brand {
    color: $grey-1000;
  }

  .food-quantity {
    font-size: 13px;
    color: $grey-800;
  }


}

.food-card-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100px;
  margin: auto;
  border-radius: 999px;
  overflow: hidden;
  background-color: $grey-300;
  box-shadow: $surround-shadow-extra-light;
  margin-bottom: 10px;

  img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }

}

.bar-code-container {
  width: 350px;
}

.basket-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  padding: 10px;
  box-shadow: $surround-shadow-light;

  .basket-content {
    display: flex;
    gap: 10px;

    .food-content {
      flex: 1;
    }
  }

  .food-line {
    display: flex;
    justify-content: left;
    gap: 5px;

    .food-card-image-container {
      margin: unset;
      width: 20px;
      height: 20px;
    }
  }
}