body {
  margin: 0;
  font-family: "Roboto";
}

button, input[type="submit"], input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  -webkit-appearance: none;
  margin: 0;
}


table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: block;
}

.subtitle {
  color: $grey-800;
  margin-bottom: 10px;
}

a {
  color: inherit;
  text-decoration: inherit;
}


h5 {
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: 500;
}

h2 {
  font-size: 23px;
  font-weight: 400;
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
}

.firebaseui-idp-text-long {
  font-size: 0 !important;

  &:after {
    font-size: 15px;
    white-space: nowrap;
    // hack, still there is no other option yet with ui-firebase
    content: "Continuer avec google";
    padding-right: 15px;
  }
}

.firebaseui-idp-button {
  width: fit-content !important;
  max-width: unset !important;
}

.danger-item {
  color: $red-700 !important;
}

.react-pdf__Document {
  height: 100%;

  canvas {
    width: 100% !important;
    height: unset !important;
  }
}

.close-modal-button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}

.MuiDialog-paper {
  max-width: 90vw !important;
}

.link-icon-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 999px;
}

.link-as-button {
  box-shadow: $surround-shadow-light;
  padding: 13px 20px;
  border-radius: 18px;
  color: white;
  background-color: $grey-900;
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }

  &.secondary {
    color: $grey-900;
    background-color: white;
  }

  &.disabled {
    background-color: $grey-600;
    color: white;
    pointer-events: none;
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 10px;

    svg {
      color: white;
    }
  }
}

.filled-icon-button {
  background-color: white !important;
  color: black !important;

  &:disabled {
    background-color: $grey-200 !important;
    color: $grey-500 !important;
  }
}

.button-as-link {
  text-decoration: underline;
}

.link-action {
  height: 100%;
  box-sizing: border-box;
  background-color: $grey-200;
  color: $grey-1000;
  padding: 15px 25px;
  border-radius: 18px;
  line-height: 1.75;

  &:hover {
    background-color: $grey-300;
  }
}