.exercice-tile {
  border-radius: 15px;
  color: $grey-700;
  text-align: center;
  width: 80px;
  padding: 15px 0;
  background-color: $grey-300;

  .exercice-weight {
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 20px;
  }

  .exercice-rep {

  }

  &.validate-exercice {
    background-color: $green-300;
    color: solid 1px $green-700;

  }

  &.failed-exercice {
    background-color: $orange-200;
    color: $orange-700;
  }
}

.exercice-tile-container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 10px;
}