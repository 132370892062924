.activity-tile-container {
  width: 100%;

  .activity-tile {
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 5px;
    justify-content: space-between;

    .tile-icon {
      margin-right: 15px;
      width: 50px;
      height: 50px;
      border-radius: 999px;
      background-color: #89c6ff;
      color: white;

      svg {
        font-size: 25px;
      }

      display: flex;
      justify-content: center;
      align-items: center;
    }

    .activity-data {
      flex: 1;
    }
  }
}


.activity-add-container {
  position: absolute;
  bottom: 30px;
  right: 30px;
  z-index: 2;

  .activity-add_button {
    background-color: white;
    border-radius: 999px;
    box-shadow: $surround-shadow-light;

    svg {
      font-size: 40px;
    }
  }
}