.extendable-container {
  background-color: white;
  width: 100%;
  padding: 20px;
  border-radius: 15px;
  box-sizing: border-box;

  .extendable-header-containter {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .extendable-header {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      flex: 1;
      text-align: left;
    }
  }

  .extend-icon {
    transform: rotate(90deg);
    transition: .4s;
    color: $grey-700;

    &.extended {
      transform: rotate(-90deg);
    }
  }

  .extendable-body {
    overflow: hidden;
    transition: height .4s;
  }

  .extendable-content {
    padding-top: 20px;
  }
}
