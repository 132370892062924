.link-tiles {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 7px;

  .tile-icon {
    width: 50px;
    height: 50px;
    border-radius: 999px;
    background-color: #89c6ff;
    color: white;

    &.green {
      background-color: $green-200;
      color: $green-500;
    }

    &.red {
      background-color: $red-200;
      color: $red-700;
    }

    &.purple {
      background-color: $purple-300;
      color: $purple-700;
    }

    &.yellow {
      background-color: $yellow-200;
      color: $yellow-800;
    }

    &.pink {
      background-color: $pink-200;
      color: $pink-800;
    }


    svg {
      font-size: 25px;
    }

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.link-filler {
  width: 170px;
}


.tile {
  margin-top: 10px;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: $surround-shadow-mid-light;
  font-size: 25px;
  font-weight: 500;

  .tile-title-container {
    margin-bottom: 20px;

    .tile-subtitle {
      font-size: 12px;
      color: $grey-700;
    }

    .tile-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 20px;
      font-weight: 500;
      color: $grey-900;


      svg {
        font-size: 25px;
        color: $grey-700;
      }
    }
  }


  .tile-action {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
}