.nutritional-row {
  display: flex;
  gap: 10px;

  & > div {
    flex: 2;

    &:first-child {
      flex: 3;
    }
  }
}