.serie-container {
  width: 100%;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;

  .serie-link {
    flex: 1;
    border-top: solid 1px $grey-400;
    display: flex;
    align-items: center;
    padding: 5px;
    margin-bottom: 5px;
    justify-content: space-between;

    & > div {
      &:first-child {
        flex: 1;
      }
    }
  }
}