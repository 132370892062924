.page-header {
  display: flex;
  align-items: center;
  padding: 20px;

  button {
    margin-right: 10px;
  }

  .page-title {
    font-size: 25px;
    font-weight: 500;
  }
}