.login-page{
  height: 100vh;
  h1{
    padding: 15px 15px 0;
    margin: 0;
  }
}

.login-card{
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .signed-form{
    width: 100%;
    button{
      width: 100%;
    }
  }
}