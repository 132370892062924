.nav-bar {
  z-index: 1;
  box-shadow: 0px 9px 12px 5px rgba(0, 0, 0, 0.1);

  .nav-content {
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 5px;
  }

  .nav-bar-header {
    img {
      width: 200px;
    }
  }

  .nav-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 10px;
    color: $grey-500;
    border-radius: 18px;
    width: 180px;
    font-weight: 500;
    font-size: 16px;

    span {
      flex: 1;
    }

    svg {
      height: 20px;

    }

    &.active {
      background-color: $grey-300;
      color: black;
    }
  }
  .highlighted-nav-item{
    position: relative;
    height: 100%;
    width: 50px;
    .nav-item{
      padding: 0;
      top: 50%; right: 50%;
      transform: translate(50%,-35%);
      height: 55px;
      width: 55px;
      background-color: $grey-1000;
      box-shadow: $surround-shadow;
      position: absolute;
      border-radius: 999px;
      svg{
        color: white;
        height: 20px;
      }
    }
  }

  .log-out-button {
    margin-top: 30px;
    text-decoration: underline;
    color: $grey-500;
  }
}

@media (max-width: $client-mobile-width) {
  .nav-bar {
    height: 69px;

    .nav-content {
      position: fixed;
      flex-direction: row;
      padding: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;

    }

    .nav-bar-header {
      display: none;
    }
    .log-out-button {
      display: none;
    }

    .nav-item {
      flex-direction: column;
      padding: 25px 0px;
      font-weight: 400;
      font-size: 13px;
      &.active {
        background-color: unset;
      }
    }
  }
}

.help-button {
  margin-top: 20px;
  text-decoration: underline;
  &.logout{
    color: $red-500;
  }
}
