.rbc-agenda-view {
  table.rbc-agenda-table {
    border: none;

    thead {
      display: none;
    }


    .rbc-agenda-time-cell {
      padding-left: 0;
      padding-right: 0;
      text-transform: unset;

      & > span {
        display: flex;
      }
    }

    .rbc-agenda-event-cell {
      display: none;
    }

    .rbc-continues-prior {
      &::before {
        display: none;
      }
    }

    .rbc-continues-after {
      &::after {
        display: none;
      }
    }

    tbody > tr > td + td {
      border-left: none;
    }

    .rbc-rtl & {
      tbody > tr > td + td {
        border-left-width: unset;
        border-right: none;
      }
    }

    tbody > tr + tr {
      border-top: none;
    }

    thead > tr > th {
      padding: unset;
      text-align: left;
      border-bottom: none;

      .rbc-rtl & {
        text-align: right;
      }
    }
  }

  .rbc-agenda-empty {
    margin: auto;
  }
}

.rbc-month-row {
  flex-basis: unset;
}

.rbc-off-range {
  & > button {
    display: none;
  }
}

.rbc-off-range-bg {
  background-color: unset;
}

.rbc-day-bg + .rbc-day-bg {
  border: none;
}

.rbc-day-bg {
  background-color: unset;
}

.rbc-month-row + .rbc-month-row {
  border: none;
}

.rbc-month-view {
  border: none;
}

.rbc-month-row {
  height: 15vw;
  max-height: 120px;
}

.rbc-row-content {
  top: 50%;
  transform: translatey(-50%);
}

.rbc-date-cell {
  padding: 0;
  text-align: center;
}

.rbc-month-header {
  order: 1;
}

.rbc-header + .rbc-header {
  border: none;
}

.rbc-header {
  border: none;
}

.rbc-event, .rbc-day-slot .rbc-background-event {
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  padding: unset;
  background-color: unset;
  border-radius: 5px;
  color: unset;
  cursor: unset;
  width: 100%;
  text-align: left;
}

.rbc-ellipsis, .rbc-show-more, .rbc-row-segment .rbc-event-content, .rbc-event-label {
  display: flex;
}